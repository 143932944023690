import { useEffect, useState } from "react";
import MyButton from "../Components/CustomButton/MyButton";
import * as icons from "react-bootstrap-icons"

const menuItems = [{
    id: 'mi1',
    title: 'Home',
    link: 'home-section',
    active: true,
    enabled: true,
  },{
    id: 'mi2',
    title: 'Mint',
    link: 'mint-section',
    active: false,
    enabled: true,
  },{
    id: 'mi3',
    title: 'AirDrop',
    link: 'airdrop-section',
    active: false,
    enabled: true,
  },{
    id: 'mi4',
    title: 'Roadmap',
    link: 'roadmap-section',
    active: false,
    enabled: true,
  },{
    id: 'mi5',
    title: 'Generations',
    link: 'generation-section',
    active: false,
    enabled: true,
  },{
    id: 'mi6',
    title: 'Information',
    link: 'information-section',
    active: false,
    enabled: true,
  },
  {
    id: 'mi9',
    title: 'White Papers',
    link: '/whitepapers',
    active: false,
    enabled: true,
  },{
    id: 'mi7',
    title: 'Team',
    link: 'team-section',
    active: false,
    enabled: true,
  },{
    id: 'mi8',
    title: 'Contact us',
    link: 'contactus-section',
    active: false,
    enabled: true,
  },
]

export default function MenuIndex() {
  let interval = null;
  const [show, setShow] = useState(false);
  
  const onClick = (id) => {
    if (id === '/whitepapers') {
      window.open('/whitepapers')
      return
    }

    window.scrollTo(document.getElementById(id).offsetLeft, document.getElementById(id).offsetTop-70);
    setShow(false);
  }

  const onMouseLeave = () => {
    interval = setTimeout(() => {
      setShow(false);
    }, 300);
  }

  const onMouseEnter = () => {
    if(interval) {
      clearTimeout(interval);
    }
  }

  return (
    <div className="menu-section">
      <div className={`menu-items ${show ? 'show-menu' : 'hide-menu'}`} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} >
        <ul>
          {
            menuItems.map((i, index) => {
              return (
                <li id={i.id} onClick={() => onClick(i.link)} className="prevent-select">
                  <a>{i.title}</a>
                </li>
              )
            })
          }
        </ul>
      </div>
      <div className={`menu-icon ${!show ? 'show-icon' : 'hide-icon'}`} onClick={() => setShow(!show)}>
        <icons.List className="prevent-select"/>
        <p className="menu-title prevent-select">{'Menu'}</p>
      </div>
    </div>
  );
}