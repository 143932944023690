import React, { useEffect, useState } from "react";
import * as icons from "react-bootstrap-icons";
import { avatars } from "../../../Utils/Constants/avatars";
import MyButton from "../../Components/CustomButton/MyButton";
import Lottie from "lottie-react";
import LottieFile from '../../../Styles/lottie/Error/exclamation.json';


export default function MintIndex() {
    const [index, setIndex] = useState(2);
    const [selected, setSelected] = useState(null);
    const [number, setNumber] = useState(1);
    const [error, setError] = useState(false);
    const manageMint = (amount) => {
        if((number + amount) < 1) {
            setNumber(1);
            setError(true);
        } else {
            setNumber(number + amount);
        }
    }
    useEffect(() => {
        if(error) {
            setTimeout(() => {
                setError(false);
            }, 1500);
        }
    },[error])
    const manageAvatar = (value) => {
        setIndex((index+value)<0?4:(index+value)>4?0:(index+value))
    }
    useEffect(() => {
        setSelected(avatars[index])
    },[index])
    return (
        <div id='mint-section' className="section-mint">
            <div className="top-side"/>
            <div className="bottom-side"/>
            <p className="section-title on-light">{'Mint'}</p>
            {
                selected &&
                <div className="mint-body">
                    <div className="gen-show">
                        <div className="manage-icon" onClick={() => manageAvatar(-1)}>
                            <icons.CaretLeftFill />
                        </div>
                        <div className="image">
                            {
                                    selected.hasVideo ?
                                    <video autoPlay loop>
                                        <source src={selected.video} type='video/webm'/>
                                    </video> : <img src={selected.image} alt={selected.alt} className={'gray'}/>
                            }
                        </div>
                        <div className="manage-icon" onClick={() => manageAvatar(1)}>
                            <icons.CaretRightFill />
                        </div>
                    </div>
                    <div className={`gen-calculator ${selected.comingSoon? 'gray' : 'normal'}`}>
                        <p className="title">{selected.title}</p>
                        {
                            (selected.comingSoon && !error) &&
                            <p className="comingSoon">
                                {selected.comingSoonText}
                            </p>
                        }
                        {
                            (!selected.comingSoon && !error) &&
                            <div className="funcs">
                                <div onClick={() => manageMint(+1)}><icons.Plus /></div>
                                <div>{number}</div>
                                <div onClick={() => manageMint(-1)}><icons.Dash /></div>
                            </div>
                        }
                        {
                            (!selected.comingSoon && !error) &&
                            <MyButton text={'Mint Now'}/>
                        }
                        {
                            error &&
                            <Lottie
                                animationData={LottieFile}
                                loop={false}
                                style={{'width':'150px'}}
                            />
                        }
                    </div>
                </div>
            }
        </div>
    );
  }
  