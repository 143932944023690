export const avatars = [
    {
        id: 1,
        image: '/Assets/generations/gen1.webp',
        alt: 'generation 1',
        title: 'Nino',
        video: '/Assets/generations/gen1_video.webm',
        order: 3,
        display: true,
        hasVideo: true,
        comingSoon: false,
        comingSoonText: '',
        abilities: [
            { name: 'Intelligence', amount: 8 },
            { name: 'Strength', amount: 27 },
            { name: 'Agility', amount: 16 },
            { name: 'Banana storage', amount: 12 },
            { name: 'Learnability', amount: 11 },
            { name: 'Sharpness', amount: 10 },
        ]
    },
    {
        id: 2,
        image: '/Assets/generations/gen2.webp',
        alt: 'comingSoon',
        title: 'Jento',
        video: '',
        order: 2,
        display: true,
        hasVideo: false,
        comingSoon: true,
        comingSoonText: 'This Generation Lunch later. Fllow our Social for more.',
        abilities: [
            { name: 'Intelligence', amount: 16 },
            { name: 'Strength', amount: 9 },
            { name: 'Agility', amount: 22 },
            { name: 'Banana storage', amount: 34 },
            { name: 'Learnability', amount: 20 },
            { name: 'Sharpness', amount: 15 },
        ]
    },
    {
        id: 3,
        image: '/Assets/generations/gen3.webp',
        alt: 'comingSoon',
        title: 'Carlo',
        video: '',
        order: 1,
        display: true,
        hasVideo: false,
        comingSoon: true,
        comingSoonText: 'This Generation Lunch later. Fllow our Social for more.',
        abilities: [
            { name: 'Intelligence', amount: 20 },
            { name: 'Strength', amount: 22 },
            { name: 'Agility', amount: 25 },
            { name: 'Banana storage', amount: 10 },
            { name: 'Learnability', amount: 15 },
            { name: 'Sharpness', amount: 25 },
        ]
    },
    {
        id: 4,
        image: '/Assets/generations/gen4.webp',
        alt: 'comingSoon',
        title: 'Tony',
        video: '',
        order: 4,
        display: true,
        hasVideo: false,
        comingSoon: true,
        comingSoonText: 'This Generation Lunch later. Fllow our Social for more.',
        abilities: [
            { name: 'Intelligence', amount: 24 },
            { name: 'Strength', amount: 34 },
            { name: 'Agility', amount: 19 },
            { name: 'Banana storage', amount: 30 },
            { name: 'Learnability', amount: 20 },
            { name: 'Sharpness', amount: 23 },
        ]
    },
    {
        id: 5,
        image: '/Assets/generations/gen5.webp',
        alt: 'comingSoon',
        title: 'Diablo',
        video: '',
        order: 5,
        display: true,
        hasVideo: false,
        comingSoon: true,
        comingSoonText: 'This Generation Lunch later. Fllow our Social for more.',
        abilities: [
            { name: 'Intelligence', amount: 32 },
            { name: 'Strength', amount: 8 },
            { name: 'Agility', amount: 18 },
            { name: 'Banana storage', amount: 14 },
            { name: 'Learnability', amount: 34 },
            { name: 'Sharpness', amount: 27 },
        ]
    }
]