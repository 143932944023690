import { useState } from "react";
import Lottie from "lottie-react";
import LottieFile from '../../../Styles/lottie/loading/loading.json';

export default function Loading() {
    const [wait, setWait] = useState(true);
    setTimeout(() => {
        setWait(false);
    }, 2900);
    return (
        <>
            {
                wait &&
                <div className="loading">
                    <Lottie
                        animationData={LottieFile}
                        loop={true}
                        style={{'maxWidth':'500px'}}
                    />
                </div>
            }
        </>
    );
  }
  