import * as icons from "react-bootstrap-icons";

const airdrop_text = [
    {
        id: 1,
        text: 'Having NFT'
    },{
        id: 2,
        text: 'Follow Twitter'
    },{
        id: 3,
        text: 'Like and retweet'
    },{
        id: 4,
        text: 'Play in test net'
    },
]
const images = [
    {
        image: '/Assets/airdrops/image_2.webp',
        alt: 'airdrops_image_2',
        display: true,
    },
    {
        image: '/Assets/airdrops/image_3.webp',
        alt: 'airdrops_image_3',
        display: true,
    },
    {
        image: '/Assets/airdrops/image_1.webp',
        alt: 'airdrops_image_1',
        display: true,
    },
]

export default function AirdropIndex() {

    return (
        <div id='airdrop-section' className="section-airdrop">
            <p className="section-title lg-text">{'To get airdrops '}</p>
            <div id="airdrop-items-box" className="airdrop-items">
                {
                    airdrop_text.map(i => {
                        return (
                            <div key={'airdropitems_'+i.id}>
                                {i.id === 1 && <icons.Icon1Circle />}
                                {i.id === 2 && <icons.Icon2Circle />}
                                {i.id === 3 && <icons.Icon3Circle />}
                                {i.id === 4 && <icons.Icon4Circle />}
                                <span>{i.text}</span>
                            </div>
                        )
                    })
                }
            </div>
            {
                images.map((i, index) => {
                    if(i.display) {
                        return(
                            <div className={`images back-image-${index}`}>
                                <img key={i.alt+Date.now()} src={i.image} alt={i.alt}/>
                            </div>
                        )
                    }
                })
            }
            
        </div>
    );
  }
  