import { social } from "../../../Utils/Constants/social";

export default function SocialIndex() {
    return (
        <div className="social">
            {
                social.filter(i => i.active).sort((a, b) => a.order - b.order).map(i => {
                    return(
                        <a key={`socialIcon_${i.id}`} href={i.link} target="_blank">
                            {i.icon}
                        </a>
                    )
                })
            }
        </div>
    );
  }
  