import { team } from "../../../Utils/Constants/team";
export default function TeamIndex() {

    return (
        <div id='team-section' className="section-team">
            <p className="section-title">{'Our team'}</p>
            <div className="team-items">
                {
                    team.map(i => {
                        return (
                            <div key={i.id} className="inner">
                                <img src={i.image} alt={i.id} />
                                <p>{i.title}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
  }
  