import Lottie from "lottie-react";
import LottieFile from '../../../Styles/lottie/ComingSoon/ComingSoon.json';

export default function ComingSoonIndex() {
    return (
        <div id='commingsoon-section' className="section-commingsoon">
            <img src="/Assets/soon/ComingSoon.webp"/>
            <div className="on-body"/>
            <div className="section-infos">
                <p className="section-title lg-text2 centered">{'Experienced monkeys will merge'}</p>
                <Lottie
                    animationData={LottieFile}
                    loop={true}
                    style={{width:'80%', maxWidth:'300px'}}
                />
            </div>
        </div>
    );
  }
  