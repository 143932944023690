import React from 'react';
import './Whitepaper.css'; // استایل‌ها را جداگانه در یک فایل CSS ذخیره می‌کنیم

const Whitepaper = () => {
  return (
    <div>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '16pt' }}><strong>Abstract</strong></p>
      <p style={{ textAlign: 'justify' }}>The Bitcoiner Apes project is an innovative platform that combines the worlds of blockchain gaming and NFTs...</p>
      <p style={{ textAlign: 'justify' }}>Bitcoiner Apes provides users with a comprehensive and unique experience by offering various features and values...</p>
      <p style={{ textAlign: 'justify' }}>The project architecture is designed in such a way that it has scalability, security and high efficiency...</p>
      <p style={{ textAlign: 'justify' }}>The Bitcoiner Apes business model consists of several revenue streams: the sale of ape NFTs...</p>
      <p style={{ textAlign: 'justify' }}>In the initial stages, Bitcoiner Apes only offers NFT tokens and there are no fungible tokens at this stage...</p>
      <p style={{ textAlign: 'justify' }}>The Bitcoiner Apes team consists of people with experience and expertise in various fields...</p>
      <p style={{ textAlign: 'justify' }}>This project aims to create a revolutionary platform in the world of blockchain games, relying on the expertise and experience of its team...</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '16pt' }}><strong>Introduction</strong></p>
      <p style={{ textAlign: 'justify' }}>In today&apos;s digital world, the video game industry and digital currencies are evolving rapidly...</p>
      <p style={{ textAlign: 'justify' }}>The Bitcoiner Apes platform is designed with the goal of creating an engaging and rewarding experience for the player community...</p>
      <p style={{ textAlign: 'justify' }}>At Bitcoiner Apes, players can measure their social standing in the crypto community by participating in tutorials and quizzes...</p>
      <p style={{ textAlign: 'justify' }}>One of the prominent features of this project is the use of blockchain technologies to ensure security and transparency...</p>
      <p style={{ textAlign: 'justify' }}>The project&rsquo;s revenue model is also very diverse and stable...</p>
      <p style={{ textAlign: 'justify' }}>The interesting story and scenario is also one of the strong points of this project...</p>
      <p style={{ textAlign: 'justify' }}>Overall, Bitcoiner Apes has created an engaging and rewarding ecosystem for its users...</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '16pt' }}><strong>Project description</strong></p>
      <p style={{ textAlign: 'justify' }}>The Bitcoiner Apes platform is an innovative project in the digital world that combines NFT-based games and digital currencies into a unique ecosystem...</p>
      <p style={{ textAlign: 'justify' }}>1- Game ecosystem</p>
      <p style={{ textAlign: 'justify' }}>Players enter a world inhabited by apes of different generations...</p>
      <p style={{ textAlign: 'justify' }}>2- The story and scenario of the game</p>
      <p style={{ textAlign: 'justify' }}>The story revolves around a tribe of apes and their leader Nino...</p>
      <p style={{ textAlign: 'justify' }}>3- Technological innovations</p>
      <p style={{ textAlign: 'justify' }}>The platform uses blockchain technologies to ensure security and transparency in collecting and trading NFTs...</p>
      <p style={{ textAlign: 'justify' }}>4- Financial model</p>
      <p style={{ textAlign: 'justify' }}>Bitcoiner Apes has a diverse and stable revenue model that includes...</p>
      <p style={{ textAlign: 'justify' }}>5- User experience and training</p>
      <p style={{ textAlign: 'justify' }}>One of the main goals of the project is to provide a rewarding and educational user experience...</p>
      <p style={{ textAlign: 'justify' }}>6- Story and characters</p>
      <p style={{ textAlign: 'justify' }}>The story has a lot of depth and charm that draws players into the world of apes and their adventures...</p>
      <p style={{ textAlign: 'justify' }}>7- Unique values of apes</p>
      <p style={{ textAlign: 'justify' }}>Each generation of apes has its own abilities and capabilities that make them unique...</p>
      <p style={{ textAlign: 'justify' }}>8- Challenges and competitions</p>
      <p style={{ textAlign: 'justify' }}>Players participate in various challenges and competitions...</p>
      <p style={{ textAlign: 'justify' }}>9- The future of the project</p>
      <p style={{ textAlign: 'justify' }}>By combining the innovations of NFT and gaming, Bitcoiner Apes has created an attractive and rewarding ecosystem...</p>
      <p style={{ textAlign: 'justify' }}>Bitcoiner Apes platform aims to create a unique and rewarding user experience...</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '16pt' }}><strong>Benefits and features</strong></p>
      <p style={{ textAlign: 'justify' }}>By combining blockchain technology and NFT-based games, the Bitcoiner Apes platform provides a unique and engaging experience for its users...</p>
      <p style={{ textAlign: 'justify' }}>1- Unique gaming experience</p>
      <p style={{ textAlign: 'justify' }}>&bull; Engaging Storytelling...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Unique characters...</p>
      <p style={{ textAlign: 'justify' }}>2- Blockchain technology and security</p>
      <p style={{ textAlign: 'justify' }}>&bull; Transparency and security...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Smart contracts...</p>
      <p style={{ textAlign: 'justify' }}>3- Educational facilities</p>
      <p style={{ textAlign: 'justify' }}>&bull; In-game training...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Training Rewards...</p>
      <p style={{ textAlign: 'justify' }}>4- Diverse income model</p>
      <p style={{ textAlign: 'justify' }}>&bull; Sell NFTs...</p>
      <p style={{ textAlign: 'justify' }}>&bull; A percentage of in-game transactions...</p>
      <p style={{ textAlign: 'justify' }}>&bull; VIP subscriptions...</p>
      <p style={{ textAlign: 'justify' }}>5- Technological developments</p>
      <p style={{ textAlign: 'justify' }}>&bull; Bitcoin Price Prediction...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Multi-generation game...</p>
      <p style={{ textAlign: 'justify' }}>6- Earning opportunities</p>
      <p style={{ textAlign: 'justify' }}>&bull; Earn money by playing the game...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Market Prediction Rewards...</p>
      <p style={{ textAlign: 'justify' }}>7- Community-oriented and solidarity</p>
      <p style={{ textAlign: 'justify' }}>&bull; Unity and cooperation...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Strong community...</p>
      <p style={{ textAlign: 'justify' }}>8- Upgradability and customization</p>
      <p style={{ textAlign: 'justify' }}>&bull; Upgrading characters...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Personalization of the game experience...</p>
      <p style={{ textAlign: 'justify' }}>9- Multipurpose platform</p>
      <p style={{ textAlign: 'justify' }}>&bull; Diverse Challenges...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Support for different devices...</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '16pt' }}><strong>Architecture and design</strong></p>
      <p style={{ textAlign: 'justify' }}>As a project based on blockchain and NFT technology, the Bitcoiner Apes platform has a complex and modern architecture...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Blockchain network...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Smart contracts...</p>
      <p style={{ textAlign: 'justify' }}>&bull; NFT Tokens...</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '16pt' }}><strong>Business model</strong></p>
      <p style={{ textAlign: 'justify' }}>Using blockchain technology and NFT-based games, the Bitcoiner Apes platform has designed a comprehensive and sustainable business model...</p>
      <p style={{ textAlign: 'justify' }}>1- NFT Sales...</p>
      <p style={{ textAlign: 'justify' }}>&bull;&nbsp; Creation and sale of unique NFTs...</p>
      <p style={{ textAlign: 'justify' }}>&bull;&nbsp; Primary and Secondary Sales...</p>
      <p style={{ textAlign: 'justify' }}>2- Transaction Fees...</p>
      <p style={{ textAlign: 'justify' }}>&bull;&nbsp; In-game trading fees...</p>
      <p style={{ textAlign: 'justify' }}>&bull;&nbsp; Blockchain Fees...</p>
      <p style={{ textAlign: 'justify' }}>3- VIP...</p>
      <p style={{ textAlign: 'justify' }}>&bull;&nbsp; Access to exclusive features...</p>
      <p style={{ textAlign: 'justify' }}>&bull;&nbsp;&nbsp; Monthly and Annual Subscriptions...</p>
      <p style={{ textAlign: 'justify' }}>4- Advertising and Partnerships...</p>
      <p style={{ textAlign: 'justify' }}>&bull; In-game advertising...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Partnering with other brands and companies...</p>
      <p style={{ textAlign: 'justify' }}>5- Ornaments and Challenges...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Holding competitions with cash and non-cash prizes...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Income through sponsors...</p>
      <p style={{ textAlign: 'justify' }}>6- Education and Quizzes...</p>
      <p style={{ textAlign: 'justify' }}>&bull;&nbsp; Training platform with rewards...</p>
      <p style={{ textAlign: 'justify' }}>7- Merchandise Sales...</p>
      <p style={{ textAlign: 'justify' }}>&bull;&nbsp; Physical Products...</p>
      <p style={{ textAlign: 'justify' }}>8- Marketing and Advertising Plans...</p>
      <p style={{ textAlign: 'justify' }}>&bull;&nbsp; Cooperation with reputable brands...</p>
      <p style={{ textAlign: 'justify' }}>&bull;&nbsp; Digital advertising campaigns...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Promotional events and contests...</p>
      <p style={{ textAlign: 'justify' }}>&bull;&nbsp; Strategic agreements with influencers...</p>
      <p style={{ textAlign: 'justify' }}>&bull;&nbsp; Advertising agreements with the media...</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '16pt' }}><strong>Tokenomics</strong></p>
      <p style={{ textAlign: 'justify' }}>The Bitcoiner Apes project has designed a comprehensive and flexible tokenomic model with a focus on providing a gaming ecosystem based on NFT (non-fungible tokens)...</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '14pt' }}><strong>Non-Fungible Tokens (NFTs)</strong></p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '12pt' }}><strong>Creation and distribution of NFTs...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; Creation of unique NFTs...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Initial distribution...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Limited availability...</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '12pt' }}><strong>Application of NFTs in the game...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; Game Characters...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Upgrade and Evolve...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Exchange and trading...</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '14pt' }}><strong>Roadmap for Fungible Tokens...</strong></p>
      <p style={{ textAlign: 'justify' }}>The Bitcoiner Apes platform also includes the necessary infrastructure and applications to add fungible tokens...</p>
      <p style={{ textAlign: 'justify' }}><strong>Creation and distribution of exchangeable tokens...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; Generate Exchangeable Tokens...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Initial distribution...</p>
      <p style={{ textAlign: 'justify' }}><strong>Use of exchangeable tokens...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; In-game transactions...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Rewards...</p>
      <p style={{ textAlign: 'justify' }}>&bull; Economic Management...</p>
      <p style={{ textAlign: 'justify' }}>We assure investors and users that the platform has a sustainable and expandable economic model...</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '14pt' }}><strong>Airdrop Plan...</strong></p>
      <p style={{ textAlign: 'justify' }}>Airdrops are one of the main ways to distribute tokens and NFTs on the Bitcoiner Apes platform...</p>
      <p style={{ textAlign: 'justify' }}><strong>1. General airdrop:</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; All users:</strong> A portion of the airdrop will be awarded to all platform users...</p>
      <p style={{ textAlign: 'justify' }}><strong>2. In-game airdrop:</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Active users...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Top players in each league...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>3. Discounts and early purchase...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Discounts...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Early Purchase...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>4. Free cards and upgrades...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Free cards...</strong></p>
      <p style={{ textAlign: 'justify' }}>This airdrop program is designed to provide a fair distribution of rewards...</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '14pt' }}><strong>NFT sale details...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; Number of each collection: 10,000</p>
      <p style={{ textAlign: 'justify' }}>&bull; Initial distribution:</p>
      <p style={{ textAlign: 'justify' }}>o Nino: 10000 NFT (4000 free)</p>
      <p style={{ textAlign: 'justify' }}>o Gento: 10,000 NFT</p>
      <p style={{ textAlign: 'justify' }}>o Carlo: 10,000 NFT</p>
      <p style={{ textAlign: 'justify' }}>o Tony: 10,000 NFT</p>
      <p style={{ textAlign: 'justify' }}>o Diablo: 10,000 NFT</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '14pt' }}><strong>Number and characteristics of NFTs...</strong></p>
      <div className="table-container">
        <table>
          <thead>
          <tr>
            <th>Characteristics</th>
            <th>Nino</th>
            <th>Gento</th>
            <th>Carlo</th>
            <th>Tony</th>
            <th>Diablo</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Sharpness</td>
            <td>10</td>
            <td>15</td>
            <td>25</td>
            <td>23</td>
            <td>27</td>
          </tr>
          <tr>
            <td>Learnability</td>
            <td>11</td>
            <td>20</td>
            <td>15</td>
            <td>20</td>
            <td>34</td>
          </tr>
          <tr>
            <td>Banana storage</td>
            <td>12</td>
            <td>34</td>
            <td>10</td>
            <td>30</td>
            <td>14</td>
          </tr>
          <tr>
            <td>Agility</td>
            <td>16</td>
            <td>22</td>
            <td>25</td>
            <td>19</td>
            <td>18</td>
          </tr>
          <tr>
            <td>Strength</td>
            <td>27</td>
            <td>9</td>
            <td>22</td>
            <td>34</td>
            <td>8</td>
          </tr>
          <tr>
            <td>Intelligence</td>
            <td>8</td>
            <td>16</td>
            <td>20</td>
            <td>24</td>
            <td>32</td>
          </tr>
          </tbody>
        </table>
      </div>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '14pt' }}><strong>Methods of upgrading and evolution of NFTs...</strong></p>
      <p style={{ textAlign: 'justify' }}>Players can upgrade their NFTs by participating in various challenges and missions...</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '14pt' }}><strong>Timing of airdrops...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; First airdrop:</strong> a part of the tokens has been assigned to the users...</p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Subsequent airdrops:</strong> at the time of the launch of the smart contract on September, 2024...</p>
      <p style={{ textAlign: 'justify' }}><strong>How the internal market works...</strong></p>
      <p style={{ textAlign: 'justify' }}>The internal market will be announced in the 6th phase of the project...</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '14pt' }}><strong>Roadmap...</strong></p>
      <p style={{ textAlign: 'justify' }}>The Bitcoiner Apes project has developed a comprehensive and step-by-step roadmap to achieve its ambitious goals...</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Phase 1: Research and Development (Q1 - Q2 2023)...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Market research and needs analysis...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Concept design and planning...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; The initial development of the platform...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Team expansion...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Phase 2: Development and Alpha Testing (Q3 - Q4 2024)...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Game Development and Nino NFTs...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Alpha testing...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Initial offering of Jento NFTs...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Phase 2- KPIs...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>Number of alpha test users...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Alpha testing...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Initial supply of Nino NFTs...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Phase 3: Initial launch and marketing development (Q1 - Q2 2025)...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Carlo NFTs initial offering...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Development of marketing strategies...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Tony NFTs initial offering...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Official Launch of the Platform...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Phase 3 -KPIs...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Amount of sales of Gento NFTs...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Number of active users...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Marketing strategies...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Phase 4: Development and Improvement (Q3 - Q4 2025)...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Diablo NFTs initial offering...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Enhance game features...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Expanding the user community...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Data analysis and continuous improvement...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Phase 4 -KPIs...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Carlo NFTs initial offering...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Number of active users...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Enhance game features...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; <strong>Expansion of the user community...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>Number of active users...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Tony NFTs initial offering...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Phase 5...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Adding Fungible Tokens...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Expansion of content and adventures...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Diablo NFTs initial offering...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Number of active users...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Performance analysis and improvement...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Phase 6: Advanced programs and long-term development (2027 and beyond)...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Performance analysis and improvement...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Expansion of content and adventures...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Development of strategic partners...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Risk and management programs...</strong></p>
      <p style={{ textAlign: 'justify' }}>To manage and reduce risks, the following plans have been developed...</p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Market risks...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Technical risks...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Competitive risks...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Users&apos; risks...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '14pt' }}><strong>Team...</strong></p>
      <p style={{ textAlign: 'justify' }}>The Bitcoiner Apes project is led by a team of experienced, multidisciplinary professionals...</p>
      <p style={{ textAlign: 'justify' }}><strong>Founder...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Experience...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Role...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Vision...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Previous projects...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Lead Developer...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Experience...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Role...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Vision...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Previous projects...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Software Developer...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Experience...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Role...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; <strong>Vision...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Previous projects...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Game Developer...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Experience...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Role...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Vision...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Previous projects...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Content Lead...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Experience...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Role...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Perspective...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Previous Projects...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Graphic Team...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; <strong>Experience...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; <strong>Role...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; <strong>Vision...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Awards and achievements...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Cryptocurrency Specialist...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; <strong>Experience...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; <strong>Role...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; <strong>Vision...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; <strong>Previous projects...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>Public Relations Team...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; <strong>Experience...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; <strong>Role...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; <strong>Vision...</strong></p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '12pt' }}><strong>Team belief...</strong></p>
      <p style={{ textAlign: 'justify' }}>All members of the Bitcoiner Apes team believe that the main asset and strength of the project comes from the presence and support of the community...</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify', lineHeight: '108%', fontSize: '14pt' }}><strong>Conclusion...</strong></p>
      <p style={{ textAlign: 'justify' }}>The Bitcoiner Apes project was started with the aim of creating an innovative platform in the world of blockchain games and NFTs...</p>
      <p style={{ textAlign: 'justify' }}><strong>Summary of benefits and features...</strong></p>
      <p style={{ textAlign: 'justify' }}>The Bitcoiner Apes project brings several benefits to its users...</p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Premium user experience...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; High security...</strong></p>
      <p style={{ textAlign: 'justify' }}>&bull; <strong>Monetization Opportunities...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>&bull; Active community...</strong></p>
      <p style={{ textAlign: 'justify' }}><strong>Architecture and design...</strong></p>
      <p style={{ textAlign: 'justify' }}>The architecture of the Bitcoiner Apes project is designed to provide scalability and high performance along with security...</p>
      <p style={{ textAlign: 'justify' }}><strong>Business model and Tokenomics...</strong></p>
      <p style={{ textAlign: 'justify' }}>The business model of the project is based on the use of NFTs...</p>
      <p style={{ textAlign: 'justify' }}><strong>Road map...</strong></p>
      <p style={{ textAlign: 'justify' }}>The project roadmap has been developed in detail and step by step to ensure that all project goals and visions are fully realized on time...</p>
      <p style={{ textAlign: 'justify' }}><strong>Introducing the team...</strong></p>
      <p style={{ textAlign: 'justify' }}>The Bitcoiner Apes project team consists of experienced and specialized people in various fields...</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;</p>
      <p style={{ textAlign: 'justify' }}><strong>End and conclusion...</strong></p>
      <p style={{ textAlign: 'justify' }}>The Bitcoiner Apes project aims to create a revolutionary platform in the world of blockchain games...</p>
      <p style={{ textAlign: 'justify' }}>As the Bitcoiner Apes team, we believe that community support and participation play a vital role in the success of the project...</p>
      <p style={{ textAlign: 'justify' }}>Converted to HTML with <a href="https://wordtohtml.net" target="_blank" style={{ fontSize: '11px', color: '#d0d0d0' }}>WordToHTML.net</a></p>
    </div>
  );
}

export default Whitepaper;
