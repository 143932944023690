export const roadmap = [
    {
        id: 'st-1',
        step: 1,
        text: "Website 90%",
        image: "/Assets/roadmap/1.png",
        link: "",
        active: false,
    },{
        id: 'st-2',
        step: 2,
        text: "monkeys complete some missions 40%",
        image: "/Assets/roadmap/2.png",
        link: "",
        active: true,
    },{
        id: 'st-3',
        step: 3,
        text: `the hungry get airdrops 61%`,
        image: "/Assets/roadmap/3.png",
        link: "",
        active: true,
    },{
        id: 'st-4',
        step: 4,
        text: "Download the app and get rewarded",
        image: "/Assets/roadmap/4.png",
        link: "",
        active: true,
    },{
        id: 'st-5',
        step: 5,
        text: "Lets Fight. Not alone anymore",
        image: "/Assets/roadmap/5.png",
        link: '',
        active: true,
    },{
        id: 'st-6',
        step: 6,
        text: "The winners will become bitcoiners",
        image: "/Assets/roadmap/6.png",
        link: '',
        active: true,
    },{
        id: 'st-7',
        step: 7,
        text: "Surprise!",
        image: "/Assets/roadmap/6.png",
        link: '',
        active: false,
    }
]