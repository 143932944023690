import * as icons from "react-bootstrap-icons";
export const social = [
    {
        id: 1,
        order: 1,
        name: 'Discord',
        icon: <icons.Discord />,
        link: 'https://discord.gg/S8rNRFBW',
        active: true,
    },{
        id: 2,
        order: 2,
        name: 'Twitter',
        icon: <icons.TwitterX/>,
        link: 'https://twitter.com/BTC_APE_NFT',
        active: true,
    },{
        id: 3,
        order: 3,
        name: 'Medium',
        icon: <icons.Medium/>,
        link: 'https://apebitcoiner.medium.com',
        active: true,
    },{
        id: 4,
        order: 4,
        name: 'Email',
        icon: <icons.Envelope/>,
        link: 'info@bitcoinerape.art',
        active: false,
    }
]