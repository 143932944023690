export const paragraphs = {
    welcomeText1: "A captivating digital forest where five generations of apes, each smarter and stronger than the last, fight and compete for survival and supremacy. Step into a world of limited edition, blockchain-based collectibles where strategy, power, and the quest for bananas collide.",
    welcomeText2: "Join the adventure, evolve and dominate the canopy!",
    abousUs: "the Bitcoiner Ape NFT project 🚀 - where the exceptional becomes the standard. This isn't just another collection; it's a gateway to an experience where creativity meets cutting-edge technology. Bitcoiner Ape project has Unique Design & Artistry , Engaging Gameplay , Exclusive community and  Benefits  and Pioneering Technology.",
    ourCommunity: "Join our vibrant community of gaming and blockchain enthusiasts on Discord and Twitter! Together, we shape the future of our project through collaborative updates and collective decision-making. Connect, contribute, and be part of our journey.",
    information: {
        infos: [
            {
                id: 'info_rewards',
                title: "Rewards",
                mini_title: 'Chests and rewards:',
                text: "There is a limited number of chests which have diamonds and are very crucial to the players as finding them will give a chance to players to win the original NFTs which are randomly embedded in these chests and will be revealed in the second season.",
            },{
                id: 'info_hungry',
                title: "I’m hungry",
                mini_title: 'How to collect bananas:',
                text: "Apes must search the forest trees to collect bananas in competition with other apes. Those who outrun their competitors will receive the double number of bananas in their basket. If they decide to save bananas, they will have to pay for the storage.",
            },{
                id: 'info_fight',
                title: "Need weapons to fight?",
                mini_title: 'Collect more weapons, have more Power!',
                text: "Apes have to overcome many enemies and challenges to collect bananas, chests and reach their goal. They have to fight with other animals to win. Apes who reach a level where they must fight other apes,  will be able to first purchase and then use some certain weapons.",
            },{
                id: 'info_competitions',
                title: "Competitions and play to earn",
                mini_title: 'The great wonder is in which box?',
                text: "Daily competitions in this project are of two types: the first type is collecting bananas which can be used for gaining energy or for sale - the second type of competition is predicting upcoming trend of Bitcoin in small time frames by saying whether the price will go up or down.",
            },{
                id: 'info_jungle',
                title: "Join the Bitcoiners in jungle",
                mini_title: '',
                text: "You are not alone in the jungle anymore. Join, find the others, compete and get surprisingly rewarded.",
            },{
                id: 'info_earn',
                title: "The Bitcoiner Apes application",
                mini_title: '',
                text: "Secure your digital life with our top-tier VPN app, designed for unmatched privacy and protection. Download now and join our project's suite of utilities, where high security meets user-centric innovation.",
            },
        ],
        images: [
            {
                id: 0,
                image: '/Assets/extra/extra_image_2.png',
                alt: 'extra_image_2',
                display: false,
            },{
                id: 1,
                image: '/Assets/extra/extra_image_6.webp',
                alt: 'extra_image_3',
                display: true,
            },{
                id: 2,
                image: '/Assets/extra/extra_image_6.webp',
                alt: 'extra_image_5',
                display: true,
            }
        ]
    }
}