import { useState } from "react";
import { paragraphs } from "../../../Utils/Constants/paragraphs";
import * as icons from "react-bootstrap-icons";

export default function InformationIndex() {
    const [selected, setSelected] = useState(null);

    return (
        <div id='information-section' className="section-information">
            <p className="section-title lg-text">{'Some information'}</p>
            
            {
                paragraphs.information.images.filter(i=>i.display).map(i => {
                    return(
                        <div className={`images back-image-${i.id}`}>
                            <img key={i.alt+Date.now()} src={i.image} alt={i.alt}/>
                        </div>
                    )
                })
            }
            <div className="information-items-acco">
                {
                    paragraphs.information.infos.map(i => {
                        return (
                            <div key={i.id} className="info-body" onClick={() => selected === i.id ? setSelected(null) : setSelected(i.id)}>
                                <div className="info-head">
                                    <h3>{i.title}</h3>
                                    <div className={`icon ${selected === i.id && 'open'}`}>
                                        <icons.CaretDownFill />
                                    </div>
                                </div>
                                <div className={`info-content ${selected === i.id && 'open'}`}>
                                    {i.mini_title !== '' && <strong>{i.mini_title}</strong>}
                                    <p>{i.text}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
  }
  