import React from 'react';
import ReactDOM from 'react-dom/client';
import './Styles/index.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';
import App from './Pages/App';
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

