import MyButton from "../../Components/CustomButton/MyButton";
import { paragraphs } from "../../../Utils/Constants/paragraphs";
import SocialIndex from "../Social/SocialIndex";
import { useEffect, useState } from "react";

export default function FooterIndex() {
    const [send, setSend] = useState(false);
    useEffect(() => {
        if(send) {
            setTimeout(() => {
                setSend(false)
            }, 5000);
        }
    },[send])
    //! formspree
    return (
        <div id='contactus-section' className="section-footer">
            <div className="footer-items">
                <div className="section-1">
                    <img src='/Assets/extra/extra_image_1.webp' alt="footer-image"/>
                    <p className="upper">{'Partnerships'}</p>
                    <div className="talk-to-us">
                        {
                            !send ?
                            <>
                                <textarea
                                    placeholder="Do you have an idea or would you like to talk to us?"
                                />
                                <MyButton
                                    text={'Send'}
                                    className={'green'}
                                    onClick={() => setSend(true)}
                                />
                            </>
                            : 
                            <>
                                <h3>{'Your message has been send!'}</h3>
                                <h4>{'Thank you'}</h4>
                            </>
                        }
                    </div>
                </div>
                <div className="divider"/>
                <div className="section-2">
                    <div className="footer-info">
                        <p>About Us</p>
                        <p>{paragraphs.abousUs}</p>
                    </div>
                    <div className="footer-info">
                        <p>Our Community</p>
                        <p>{paragraphs.ourCommunity}</p>
                    </div>
                </div>
            </div>
            <SocialIndex />
        </div>
    );
  }
  