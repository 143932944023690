import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Home from "./Home";
import Whitepapers from "./Whitepapers";

const Main = () => {
  return (
    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' element={<Home/>}></Route>
      <Route exact path='/whitepapers' element={<Whitepapers/>}></Route>
    </Routes>
  );
}

export default Main;