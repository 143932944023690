import React, { useEffect, useState } from "react";
import { avatars } from "../../../Utils/Constants/avatars";
export default function GenerationsIndex() {
    const [selected, setSelected] = useState(avatars[0])
    useEffect(() => {

    },[])
    return (
        <div id='generation-section' className="section-generations">
            <p className="section-title lg-text">{'Generations'}</p>
            <div className="generations-items">
                {
                    avatars.sort((a, b) => a.order - b.order).map(i => {
                        return (
                            <div
                                key={'generations_'+i.id}
                                className={`each ${selected.id === i.id ? 'active' : 'deactive'}`}
                                onClick={() => {setSelected(i)}}
                            >
                                <img src={i.image} alt={i.alt}/>
                                <p>{i.title}</p>
                            </div>
                        )
                    })
                }
            </div>
            <p className="section-title lg-text2">{`${selected.title}'s Ability`}</p>
            <div className="generations-abilities">
                {
                    selected && selected.abilities.map(i => {
                        return (
                            <div key={'abilities_' + i.name} className="abilities-inner">
                                <p className="title">{i.name}</p>
                                <div className="perc">
                                    <div className="perc-inner" style={{ width: `${i.amount}%`}}>
                                        <p className="amount">{i.amount}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
  }
  