import Loading from "./Components/Loading/Loading";
import Header from "./Header";
import MenuIndex from "./Menu";
import AirdropIndex from "./Sections/Airdrop/AirdropIndex";
import ComingSoonIndex from "./Sections/ComingSoon/ComingSoonIndex";
import FooterIndex from "./Sections/Footer/FooterIndex";
import GenerationsIndex from "./Sections/Generations/GenerationsIndex";
import Info from "./Sections/info";
import InformationIndex from "./Sections/Information/InformationIndex";
import MintIndex from "./Sections/Mint/MintIndex";
import RoadmapIndex from "./Sections/Roadmap/RoadmapIndex";
import TeamIndex from "./Sections/Team/TeamIndex";
import WelcomeIndex from "./Sections/Welcome/WelcomeIndex";
import WalletIndex from "./Wallet";
import Main from "./Main";

export default function App() {
  return (<div className="App">
    <Main/>
  </div>)
}
