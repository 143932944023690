export const team = [
    {
        id: 'member-1',
        image: "/Assets/team/member_1.webp",
        title: 'PhD in economics'
    },{
        id: 'member-2',
        image: "/Assets/team/member_2.webp",
        title: 'Graphic designer'
    },{
        id: 'member-3',
        image: "/Assets/team/member_3.webp",
        title: 'Programmer'
    },{
        id: 'member-4',
        image: "/Assets/team/member_4.webp",
        title: 'AI programmer'
    },{
        id: 'member-5',
        image: "/Assets/team/member_5.webp",
        title: 'Blockchain expert'
    }
]